/* eslint-disable prettier/prettier */
/* eslint-disable react/display-name */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicated-branches */
import React from 'react';
import {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  RACSelect,
  RACButton,
  RACDatePicker,
  RACToggle,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  RACModalCard,
} from '@rentacenter/racstrap';
import { GetDropdownvalues } from '../../../Stub/Stub';
import moment from 'moment';
import { agrGeneralInfoStyles } from '../../../JSstyles/agrGeneralInfoStyles';
import { AgreementContext } from '../../../context/AgreementContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { paymentSchedule } from '../../../JSstyles/paymentSchedule';
import { FeatureFlagContext } from '../../../context/FeatureFlagContext';

interface Params {
  customerId: string;
  agreementId: string;
}
const GeneralInformation = forwardRef((props: any, ref) => {
  const {setSalesInfoResponse} = useContext(AgreementContext);
  useImperativeHandle(ref, () => ({
    DropdownReset() {
      setDropdownValues({
        ...DropdownValues,
        Schedule: successDropdownValues.Schedule,
        Range: successDropdownValues.Range,
        DeliveryCharge: successDropdownValues.Deliverycharge,
        DayExtension: successDropdownValues.DaysExtension,
        PromoCode: successDropdownValues.PromoCode,
        Policy: successDropdownValues.Policy,
      });
      props.setPromoCodeValue(successDropdownValues.PromoCode);
      setTermValue(successDropdownValues.Term);
      setOptServiceDropdownValue(successDropdownValues.OptionalService);
      setDropdown({ ...Dropdown, Duedate: successDropdownValues.Duedate });
      if (successDropdownValues.Schedule == 'WK') {
        setSchedule({
          ...Schedule,
          WeeklySchedule: true,
          BiWeeklySchedule: false,
          MonthlySchedule: false,
          SemiMonthlySchedule: false,
        });
      } else if (successDropdownValues.Schedule == 'BIWK') {
        setSchedule({
          ...Schedule,
          WeeklySchedule: false,
          BiWeeklySchedule: true,
          MonthlySchedule: false,
          SemiMonthlySchedule: false,
        });
      } else if (successDropdownValues.Schedule == 'MON') {
        setSchedule({
          ...Schedule,
          WeeklySchedule: false,
          BiWeeklySchedule: false,
          MonthlySchedule: true,
          SemiMonthlySchedule: false,
        });
      } else {
        setSchedule({
          ...Schedule,
          WeeklySchedule: false,
          BiWeeklySchedule: false,
          MonthlySchedule: false,
          SemiMonthlySchedule: true,
        });
      }
    },
  }));
  const paymentScheduleClassName = paymentSchedule();

  //PS_AD-1 A object declared to store Payment schedule values
  const PaymentScheduleObj = {
    weeklyRate: 0,
    weeklyTerm: 0,
    biweeklyRate: 0,
    biweeklyTerm: 0,
    semimonthlyRate: 0,
    semimonthlyTerm: 0,
    monthlyRate: 0,
    monthlyTerm: 0,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [PaymentScheduleValues, setPaymentScheduleValues] =
    useState(PaymentScheduleObj);

  const { agreementOptions, successDropdownValues, setsuccessDropdownValues } =
    useContext(AgreementContext);
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  //change by Me
  const API_ERROR_MESSAGE = 'Unable to fetch the data';
  const classes = props.throwStyle;
  const agrGeneralInfoClassName = agrGeneralInfoStyles();
  //PS_AD-1 creating object to store all dropdown input values
  const DropdownvaluesObj = {
    AgreementSource: 'Walkin',
    Range: '',
    DeliveryCharge: '',
    Duedate: '',
    ExtensionDays: '',
    Schedule: '',
    Optservices: 'LDW & Benifitplus',
    Salesperson: '',
    Policy: 'RAC Tire Plus',
  };
  //PS_AD-1 creating object to store css name for highlight
  const colorObj = {
    WeeklySchedule: false,
    BiWeeklySchedule: false,
    MonthlySchedule: false,
    SemiMonthlySchedule: false,
  };
  //PS_AD-1 creating object to store all dropdown options
  const DropdownOptionsObj = {
    AgreementSource: [],
    Schedulevalues: [],
    DeleiveryCharge: [],
    DaysExtension: [],
    PromoCode: [],
    OPtServices: [],
    SalesPerson: [],
    Range: [],
  };
  const DropdownValuesObj = {
    AgreementSource: 'W',
    Schedule: '',
    DeliveryCharge: '',
    DayExtension: '0',
    OptionalServices: 'NONE',
    SalesPerson: '',
    Range: 'SEMI1-15',
    Policy: 'RAC Tire Plus',
    PromoCode: '',
  };
  const DropDownChangeState = {
    Schedule: false,
    Range: false,
    DeliveryCharge: false,
    Duedate: false,
    ExtensionDays: false,
    Optservices: false,
    PromoCode: false,
    Policy: false,
  };
  const PayScheduleObj = {
    PayschedulePeriod: '',
    PayscheduleDay: '',
  };
  const search = useLocation().search;
  const salesLeadId = new URLSearchParams(search).get('salesLeadId');
  //PS_AD-1 creating state variable to hold DropdownvaluesObj
  const [Dropdown, setDropdown] = useState(DropdownvaluesObj);
  //PS_AD-1 creating state variable to hold DropdownOptionsObj
  const [DropdownOptions, setDropdownOptions] = useState(DropdownOptionsObj);
  const [DeliveryChargeOptions, setDeliveryChargeOptions] = useState([]);
  const [TermValue, setTermValue] = useState('');
  const [DropdownValues, setDropdownValues] = useState(DropdownValuesObj);
  const [OptServiceDropdownValue, setOptServiceDropdownValue] = useState('');
  const [DropdownChanged, setDropdownChanged] = useState(DropDownChangeState);
  //PS_AD-1 creating state variable to handle dropdown enable and disable mode
  const [DropDownDisable, setDropDownDisable] = useState(false);
  //PS_AD-1 creating state variable to handle recalculate button enable and disable mode
  const [RecalculateState, setRecalculateState] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [SelectedSchedule, setSelectedSchedule] = useState();
  const [Schedule, setSchedule] = useState(colorObj);
  //PS_AD-1 creating state variable to hide and show range active dropdown
  const [Rangeactive, setRangeactive] = useState(false);
  //PS_AD-1 creating state variable to handle autopay toggle
  const [AutoPayToggle, setAutoPayToggle] = useState(props.AutoPayToggleStatus);
  //PS_AD-1 creating state variable to store agreement type
  const [AgreementType, setAgreementType] = useState('Rental');
  //PS_AD-1 creating state variable to store paySchedule
  const [Payschedule, setPayschedule] = useState(PayScheduleObj);
  //PS_AD-1 creating state variable to store current date
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [CurrentDate, setCurrentDate] = useState('');
  const [MaxDate, setMaxDate] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [OptServicesDisable, setOptServicesDisable] = useState(false);
  const [PolicyDropdownVisible, setPolicyDropdownVisible] = useState(false);
  const [PayScheduleVal, setPayScheduleVal]: any = useState();
  const [RecalculateReq, setRecalculateReq] = useState({});
  const [PaymentScheduleEnabled, setPaymentScheduleEnabled] = useState(true);
  const [DropdownLoading, setDropdownLoading] = useState(true);
  const [DaysExtensionOptions, setDaysExtensionOptions] = useState([]);
  const [AddingBpclubOpen, setAddingBpclubOpen] = useState(false);
  const [DecliningLdwOpen, setDecliningLdwOpen] = useState(false);
  //changed by Me
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  const [PolicyDropDownValues, setPolicyDropDownValues] = useState([
    { value: 'RAC Tire Plus', label: 'RAC Tire Plus' },
    { value: 'None', label: 'None' },
  ]);
  const [GetDropdownApiResponse, setGetDropdownApiResponse] = useState();
  const CLUB_STATUS_ACTIVE_PENDING = ['A', 'P'];

  useEffect(() => {
    if (props.PaymentScheduleValues !== undefined) {
      setPaymentScheduleValues(props.PaymentScheduleValues);
    }
  }, [props.PaymentScheduleValues]);

  useEffect(() => {
    console.log('salesLeadId', salesLeadId);
    console.log('agreementOptions', agreementOptions);
    async function initialpageload() {
      const dropval: any = await GetDropdownvalues();
      setDropdownLoading(false);
      const dropdowndata: any = agreementOptions;
      let optserv = dropdowndata.optServices;
      console.log('CustomerCoverageData props', props.CustomerCoverageData);
      console.log('Dynamic dropdown values', dropdowndata);
      const customerCoverageResult = props.CustomerCoverageData;
      if (props.CustomerCoverageData !== undefined) {
        if (
          CLUB_STATUS_ACTIVE_PENDING.includes(
            customerCoverageResult.clubInfo.clubStatus
          ) ||
          customerCoverageResult.storeCoverageStatus.clubOnAgreementCreation ===
          false
        ) {
          optserv = optserv.filter(
            (item) => item.refCode != 'BPC' && item.refCode != 'LDWC'
          );
        }
        if (customerCoverageResult.storeCoverageStatus.isLDWAllowed === false) {
          optserv = optserv.filter(
            (item) => item.refCode != 'LDW' && item.refCode != 'LDWC'
          );
        }
        if (customerCoverageResult.storeCoverageStatus.isLdwTiedWithClub) {
          optserv = optserv.filter((item) => item.refCode != 'BPC');
        }
      }
      console.log('optserv', optserv);

      const RangeRawValueArray = dropdowndata.schedule.filter(
        (word) => word.desc === 'Semi-Monthly' && word.refCode !== 'SEMI'
      );
      const SortRangeRawValueArray = RangeRawValueArray.sort(
        (a, b) => parseInt(a.displaySeq) - parseInt(b.displaySeq)
      );
      console.log('SortRangeRawValueArray', SortRangeRawValueArray);
      const Rangeval = SortRangeRawValueArray.map((ele: any) => {
        const value = ele.refCode;
        const tempval = value.replace(/SEMI/g, '');
        const tempArr = tempval.split('-');
        const finalrange = Addsuffix(tempArr[0]) + ' ' + Addsuffix(tempArr[1]);
        return { value: ele.refCode, label: finalrange };
      });
      console.log('Rangeval', Rangeval);
      const UniqSchedulevalues = dropdowndata.schedule.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.desc === value.desc)
      );
      const SourceArray: any = dropdowndata.agreementSource.map((ele: any) => {
        return { value: ele.refCode, label: ele.desc };
      });
      const SCHArray: any = UniqSchedulevalues.map((ele: any) => {
        return { value: ele.refCode, label: ele.desc };
      });
      const SPArray: any = dropdowndata.salesPerson.map((ele: any) => {
        return { value: ele.id.toString(), label: ele.name };
      });
      const OPArray: any = optserv.map((ele: any) => {
        return { value: ele.refCode, label: ele.desc };
      });
      const DEArray: any = dropval[0].daysextension.map((ele: any) => {
        return { value: ele, label: ele };
      });
      const DefaultDropdownValue = {
        value: '',
        label: 'Select',
      };
      console.log('SourceArray', SourceArray);
      console.log('SCHArray', SCHArray);
      const SourceArraySort = SourceArray.sort(function (a, b) {
        return a.label.localeCompare(b.label);
      });
      const SPArraySort = SPArray.sort(function (a, b) {
        return a.label.localeCompare(b.label);
      });
      SPArraySort.splice(0, 0, DefaultDropdownValue);
      const LDWcheck: any = OPArray.filter((ele) => ele.value === 'LDW');
      const LDWCcheck: any = OPArray.filter((ele) => ele.value === 'LDWC');
      console.log('LDWcheck', LDWcheck);
      console.log('LDWCcheck', LDWCcheck);
      console.log('TireAgreementCheck', props.TireAgreement);

      if (LDWcheck.length > 0 && LDWCcheck.length === 0) {
        console.log('LDW alone check');
        setOptServiceDropdownValue('LDW');
      }
      if (LDWCcheck.length > 0) {
        console.log('LDWith club alone check');
        setOptServiceDropdownValue('LDWC');
      }
      if (LDWcheck.length === 0 && LDWCcheck.length === 0) {
        setOptServiceDropdownValue('NONE');
      }
      if(customerCoverageResult && customerCoverageResult.storeCoverageStatus && !customerCoverageResult.storeCoverageStatus.isPolicyAllowed && customerCoverageResult.GetPriceQuoteResponse.policyApplicable == 1){
        // setPolicyDropDownValues([{ value: 'None', label: 'None' }])
        // setDropdownValues({...DropdownValues,Policy : 'None'})
        setOptServiceDropdownValue('NONE');
      }

      setDropdownOptions({
        ...DropdownOptions,
        AgreementSource: SourceArraySort,
        Schedulevalues: SCHArray,
        DaysExtension: DEArray,
        OPtServices: OPArray,
        SalesPerson: SPArraySort,
        Range: Rangeval,
      });
    }
    if (
      props.IsPendingAgreement === false &&
      props.CustomerCoverageData !== undefined
    ) {
      initialpageload();
    } else if (props.IsPendingAgreement === true) {
      const PendingDropdownOptions = props.PendingAgreementOptions;
      console.log('PendingDropdownOptions', PendingDropdownOptions);
      const dropval: any = GetDropdownvalues();
      const DEArray: any = dropval[0].daysextension.map((ele: any) => {
        return { value: ele, label: ele };
      });
      if (PendingDropdownOptions !== undefined) {
        setDropdownLoading(false);
        if (
          PendingDropdownOptions.schedule[0].value.includes('SEMI') === true
        ) {
          const Rangeval = PendingDropdownOptions.schedule.map((ele: any) => {
            const value = ele.value;
            const tempval = value.replace(/SEMI/g, '');
            const tempArr = tempval.split('-');
            const finalrange =
              Addsuffix(tempArr[0]) + ' ' + Addsuffix(tempArr[1]);
            return { value: ele.value, label: finalrange };
          });
          const SemiMonthlySchedule: any = [
            { value: 'SEMI1-15', label: 'Semi-Monthly' },
          ];
          console.log('Pending agreement range', Rangeval);
          setDropdownOptions({
            ...DropdownOptions,
            AgreementSource: PendingDropdownOptions.agreementSource,
            Schedulevalues: SemiMonthlySchedule,
            DeleiveryCharge: PendingDropdownOptions.deliveryCharge,
            DaysExtension: DEArray,
            OPtServices: PendingDropdownOptions.optServices,
            SalesPerson: PendingDropdownOptions.salesPerson,
            Range: Rangeval,
          });
          setDeliveryChargeOptions(PendingDropdownOptions.deliveryCharge);
          props.setPromoCodeDropDownOptions(PendingDropdownOptions.promoCode);
        } else {
          setDropdownOptions({
            ...DropdownOptions,
            AgreementSource: PendingDropdownOptions.agreementSource,
            Schedulevalues: PendingDropdownOptions.schedule,
            DeleiveryCharge: PendingDropdownOptions.deliveryCharge,
            DaysExtension: DEArray,
            OPtServices: PendingDropdownOptions.optServices,
            SalesPerson: PendingDropdownOptions.salesPerson,
          });
          setDeliveryChargeOptions(PendingDropdownOptions.deliveryCharge);
          props.setPromoCodeDropDownOptions(PendingDropdownOptions.promoCode);
        }
      }
    }
    setAgreementType('Rental');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.IsPendingAgreement,
    props.PendingAgreementOptions,
    props.CustomerCoverageData,
    GetDropdownApiResponse,
  ]);
  useEffect(()=>{
    const customerCoverageResult = props.CustomerCoverageData;
      if(customerCoverageResult && customerCoverageResult.storeCoverageStatus && !customerCoverageResult.storeCoverageStatus.isPolicyAllowed && customerCoverageResult.GetPriceQuoteResponse.policyApplicable == 1){
        setPolicyDropDownValues([{ value: 'None', label: 'None' }])
        setDropdownValues({...DropdownValues,Policy : 'None'})
        setOptServiceDropdownValue('NONE');
      }
  },[
    props.IsPendingAgreement,
    props.PendingAgreementOptions,
    props.CustomerCoverageData,
    GetDropdownApiResponse,
  ])
  useEffect(() => {
    if (props.TireAgreement === true) {
      console.log('None check');
      setOptServiceDropdownValue('NONE');
    }
  }, [props.TireAgreement, DropdownOptions.OPtServices]);
  useEffect(() => {
    if (props.PolicyEnabled === true) {
      setPolicyDropdownVisible(true);
      setOptServicesDisable(true);
    }
  }, [props.PolicyEnabled]);
  useEffect(() => {
    if (props.IsPendingAgreement === false) {
      const DefaultDropdownValue = {
        value: '',
        label: 'Select',
      };
      const DeliveryChargeValues = props.DeliveryChargeFeeValues;
      const DCArray: any = DeliveryChargeValues.map((ele: any) => {
        return { value: ele, label: ele };
      });
      DCArray.splice(0, 0, DefaultDropdownValue);
      setDeliveryChargeOptions(DCArray);
    }
  }, [
    props.DeliveryChargeDisable,
    props.DeliveryChargeFeeValues,
    props.IsPendingAgreement,
  ]);
  useEffect(() => {
    setAutoPayToggle(props.AutoPayToggleStatus);
  }, [props.AutoPayToggleStatus]);
  useEffect(() => {
    setDropDownDisable(props.EditDisablemode);
    if (props.EditDisablemode === true) {
      setOptServicesDisable(true);
    } else if (
      props.EditDisablemode === false &&
      props.PolicyEnabled === false
    ) {
      setOptServicesDisable(false);
    }
  }, [props.EditDisablemode, props.PolicyEnabled]);
  useEffect(() => {
    const currentdate = moment().add(1, 'days').format('YYYY-MM-DD');
    const maximumdate = moment().add(3, 'months').format('YYYY-MM-DD');
    setCurrentDate(currentdate);
    setMaxDate(maximumdate);
    const DueDate: any = new Date(moment(Dropdown.Duedate).format('MM/DD/YYYY'));
    console.log(DueDate, 'DueDate')
    const CurrentDate: any = new Date(moment(new Date()).format('MM/DD/YYYY'));
    console.log(CurrentDate, 'Current Date');
    const diffTime = Math.abs(DueDate - CurrentDate);
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
    console.log('Differ', diffDays);
    const ExtLimitArray: any = [];
    for (let i = 0; i <= diffDays; i++) {
      if (ExtLimitArray.length <= 31) {
        ExtLimitArray.push(i.toString());
      }
    }
    console.log('Generated ext days Array', ExtLimitArray);
    if (ExtLimitArray.length !== 0) {
      const DropDownFormat: any = ExtLimitArray.map((ele: any) => {
        return { value: ele, label: ele };
      });
      console.log('DropDownFormat', DropDownFormat);
      setDaysExtensionOptions(DropDownFormat);
    }
  }, [Dropdown.Duedate]);
  useEffect(() => {
    props.settempSchedule(Schedule);
    if (
      DropdownChanged.Schedule === true ||
      DropdownChanged.DeliveryCharge === true ||
      DropdownChanged.Duedate === true ||
      DropdownChanged.ExtensionDays === true ||
      DropdownChanged.Optservices === true ||
      DropdownChanged.PromoCode === true ||
      DropdownChanged.Range === true ||
      DropdownChanged.Policy === true
    ) {
      setRecalculateState(false);
      RecalculateBtnClick();
    } else if (
      DropdownChanged.Schedule === false ||
      DropdownChanged.DeliveryCharge === false ||
      DropdownChanged.Duedate === false ||
      DropdownChanged.ExtensionDays === false ||
      DropdownChanged.Optservices === false ||
      DropdownChanged.PromoCode === false ||
      DropdownChanged.Range === false ||
      DropdownChanged.Policy === false
    ) {
      setRecalculateState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DropdownChanged]);
  useEffect(() => {
    if (
      props.IsPendingAgreement === false &&
      props.PendingAgreementDropdownValues === undefined
    ) {
      console.log('im inside pending false');
      if (props.AgreementSchedule !== undefined) {
        setDropdownChanged({
          ...DropdownChanged,
          Schedule: false,
          Range: false,
          DeliveryCharge: false,
          Duedate: false,
          ExtensionDays: false,
          Optservices: false,
          PromoCode: false,
          Policy: false,
        });
        setRecalculateState(true);
        // eslint-disable-next-line no-console
        console.log('Schedule for dropdown', props.AgreementSchedule.schedule);
        if (props.AgreementSchedule.schedule.includes('SEMI')) {
          setDropdownValues({
            ...DropdownValues,
            Schedule: 'SEMI1-15',
            Range: props.AgreementSchedule.schedule,
          });
          setRangeactive(true);
          console.log('props.CalenderDuedate', props.CalenderDuedate);
          setsuccessDropdownValues({
            ...successDropdownValues,
            Schedule: 'SEMI1-15',
            Range: props.AgreementSchedule.schedule,
            Duedate: props.CalenderDuedate,
          });
        } else {
          setDropdownValues({
            ...DropdownValues,
            Schedule: props.AgreementSchedule.schedule,
          });
          setRangeactive(false);
          setsuccessDropdownValues({
            ...successDropdownValues,
            Schedule: props.AgreementSchedule.schedule,
            Duedate: props.CalenderDuedate,
          });
        }
      }
    } else if (props.IsPendingAgreement === true) {
      console.log('im inside pending true');
      const PendingDropdownValues = props.PendingAgreementDropdownValues;
      console.log('PendingDropdownValues', PendingDropdownValues);
      if (
        props.AgreementSchedule !== undefined &&
        PendingDropdownValues !== undefined
      ) {
        setDropdownChanged({
          ...DropdownChanged,
          Schedule: false,
          Range: false,
          DeliveryCharge: false,
          Duedate: false,
          ExtensionDays: false,
          Optservices: false,
          PromoCode: false,
          Policy: false,
        });
        setRecalculateState(true);
        // eslint-disable-next-line no-console
        console.log('Schedule for dropdown', props.AgreementSchedule.schedule);
        if (props.AgreementSchedule.schedule.includes('SEMI')) {
          setTermValue(props.PayScheduleValues.semimonthlyTerm);
          setSchedule({
            ...Schedule,
            WeeklySchedule: false,
            BiWeeklySchedule: false,
            MonthlySchedule: false,
            SemiMonthlySchedule: true,
          });
          const pendingDeliCharge = parseFloat(
            PendingDropdownValues.deliveryChargeValue
          );
          console.log('pendingDeliCharge', pendingDeliCharge.toFixed(2));
          setDropdownValues({
            ...DropdownValues,
            Schedule: 'SEMI1-15',
            Range: props.AgreementSchedule.schedule,
            AgreementSource: PendingDropdownValues.agreementSourceValue,
            DeliveryCharge: pendingDeliCharge.toFixed(2),
            DayExtension: PendingDropdownValues.daysExtensionValue,
            OptionalServices: PendingDropdownValues.optServicesValue,
            PromoCode: PendingDropdownValues.PromoCodeValue,
            SalesPerson: PendingDropdownValues.salesPersonValue,
            Policy: PendingDropdownValues.policyValue,
          });
          props.setPromoCodeValue(PendingDropdownValues.PromoCodeValue);
          setOptServiceDropdownValue(PendingDropdownValues.optServicesValue);
          setRangeactive(true);
        } else {
          const pendingDeliCharge = parseFloat(
            PendingDropdownValues.deliveryChargeValue
          );
          console.log('pendingDeliCharge', pendingDeliCharge.toFixed(2));
          setDropdownValues({
            ...DropdownValues,
            Schedule: props.AgreementSchedule.schedule,
            AgreementSource: PendingDropdownValues.agreementSourceValue,
            DeliveryCharge: pendingDeliCharge.toFixed(2),
            DayExtension: PendingDropdownValues.daysExtensionValue,
            OptionalServices: PendingDropdownValues.optServicesValue,
            PromoCode: PendingDropdownValues.PromoCodeValue,
            SalesPerson: PendingDropdownValues.salesPersonValue,
            Policy: PendingDropdownValues.policyValue,
          });
          props.setPromoCodeValue(PendingDropdownValues.PromoCodeValue);
          setOptServiceDropdownValue(PendingDropdownValues.optServicesValue);
          setRangeactive(false);
          if (props.AgreementSchedule.schedule === 'WK') {
            setSchedule({
              ...Schedule,
              WeeklySchedule: true,
              BiWeeklySchedule: false,
              MonthlySchedule: false,
              SemiMonthlySchedule: false,
            });
            setTermValue(props.PayScheduleValues.weeklyTerm);
          } else if (props.AgreementSchedule.schedule === 'BIWK') {
            setSchedule({
              ...Schedule,
              WeeklySchedule: false,
              BiWeeklySchedule: true,
              MonthlySchedule: false,
              SemiMonthlySchedule: false,
            });
            setTermValue(props.PayScheduleValues.biweeklyTerm);
          } else if (props.AgreementSchedule.schedule === 'MON') {
            setSchedule({
              ...Schedule,
              WeeklySchedule: false,
              BiWeeklySchedule: false,
              MonthlySchedule: true,
              SemiMonthlySchedule: false,
            });
            setTermValue(props.PayScheduleValues.monthlyTerm);
          }
        }
      }
    }

    if (props.EmployerPayschedule != undefined) {
      // eslint-disable-next-line no-console
      console.log(
        'Employer payschedule in child component--->',
        props.EmployerPayschedule
      );
      if (
        props.EmployerPayschedule.schedule != undefined &&
        props.EmployerPayschedule.daysPaid != undefined
      ) {
        setPaymentScheduleEnabled(true);
        if (
          props.EmployerPayschedule.schedule.includes('Semi-Monthly') ===
          true ||
          props.EmployerPayschedule.schedule.includes('Monthly') === true
        ) {
          if (props.EmployerPayschedule.daysPaid.includes('&') === true) {
            const arr = props.EmployerPayschedule.daysPaid.split('&');
            const convertedPayschedule =
              Addsuffix(arr[0]) + ' & ' + Addsuffix(arr[1]);
            setPayschedule({
              ...Payschedule,
              PayschedulePeriod: props.EmployerPayschedule.schedule,
              PayscheduleDay: convertedPayschedule,
            });
          } else {
            setPayschedule({
              ...Payschedule,
              PayschedulePeriod: props.EmployerPayschedule.schedule,
              PayscheduleDay: Addsuffix(props.EmployerPayschedule.daysPaid),
            });
          }
        } else {
          setPayschedule({
            ...Payschedule,
            PayschedulePeriod: props.EmployerPayschedule.schedule,
            PayscheduleDay: props.EmployerPayschedule.daysPaid,
          });
        }
      } else {
        setPaymentScheduleEnabled(false);
      }
    }
    if (props.PayScheduleValues != undefined) {
      setPayScheduleVal(props.PayScheduleValues);
      console.log('props.CalenderDuedate', props.CalenderDuedate);
      setDropdown({ ...Dropdown, Duedate: props.CalenderDuedate });
      // eslint-disable-next-line no-console
      console.log('Weekly term from props', props.PayScheduleValues.weeklyTerm);
      if (
        props.AgreementSchedule.schedule != undefined &&
        props.IsPendingAgreement === false &&
        props.PendingAgreementDropdownValues === undefined
      ) {
        if (props.AgreementSchedule.schedule === 'WK') {
          console.log('Inside Weekly schedule');
          console.log('Weekly term', props.PayScheduleValues.weeklyTerm);
          const temp = props.PayScheduleValues.weeklyTerm;
          const tempstr = temp.toString();
          setTermValue(props.PayScheduleValues.weeklyTerm);
          console.log('term number', temp);
          console.log('term string', tempstr);
          setDropdownValues({
            ...DropdownValues,
            Schedule: 'WK',
            AgreementSource: salesLeadId === null || undefined ? 'W' : 'I',
          });

          setSchedule({
            ...Schedule,
            WeeklySchedule: true,
            BiWeeklySchedule: false,
            MonthlySchedule: false,
            SemiMonthlySchedule: false,
          });
        } else if (props.AgreementSchedule.schedule === 'BIWK') {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          console.log('Inside biweekly schedule');
          console.log('BiWeekly term', props.PayScheduleValues.biweeklyTerm);
          setTermValue(props.PayScheduleValues.biweeklyTerm);
          setDropdownValues({
            ...DropdownValues,
            Schedule: 'BIWK',
            AgreementSource: salesLeadId === null || undefined ? 'W' : 'I',
          });

          setSchedule({
            ...Schedule,
            WeeklySchedule: false,
            BiWeeklySchedule: true,
            MonthlySchedule: false,
            SemiMonthlySchedule: false,
          });
        } else if (props.AgreementSchedule.schedule === 'MON') {
          console.log('Inside monthly schedule');
          console.log('Monthly term', props.PayScheduleValues.monthlyTerm);
          setTermValue(props.PayScheduleValues.monthlyTerm);
          setDropdownValues({
            ...DropdownValues,
            Schedule: 'MON',
            AgreementSource: salesLeadId === null || undefined ? 'W' : 'I',
          });
          setSchedule({
            ...Schedule,
            WeeklySchedule: false,
            BiWeeklySchedule: false,
            MonthlySchedule: true,
            SemiMonthlySchedule: false,
          });
        }
        // eslint-disable-next-line sonarjs/no-duplicate-string
        else if (props.AgreementSchedule.schedule.includes('SEMI') === true) {
          console.log('Inside semimonthly schedule');
          console.log('BiWeekly term', props.PayScheduleValues.semimonthlyTerm);
          setTermValue(props.PayScheduleValues.semimonthlyTerm);
          setDropdownValues({
            ...DropdownValues,
            Schedule: 'SEMI1-15',
            Range: props.AgreementSchedule.schedule,
            AgreementSource: salesLeadId === null || undefined ? 'W' : 'I',
          });
          setSchedule({
            ...Schedule,
            WeeklySchedule: false,
            BiWeeklySchedule: false,
            MonthlySchedule: false,
            SemiMonthlySchedule: true,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.CalenderDuedate,
    props.AgreementSchedule,
    props.EmployerPayschedule,
    props.PayScheduleValues,
    props.PendingAgreementDropdownValues,
    props.IsPendingAgreement,
  ]);
  useEffect(() => {
    if (DropdownValues.SalesPerson !== '' && RecalculateState === true) {
      props.setNextBtnDisabled(false);
    }
    if (DropdownValues.SalesPerson === '') {
      props.setNextBtnDisabled(true);
    }
    if (RecalculateState === false) {
      props.setNextBtnDisabled(true);
    }
  }, [DropdownValues, RecalculateState]);

  const Addsuffix = (value) => {
    const covertvalue = parseInt(value);
    if (covertvalue < 1000) {
      const FirstCheck = covertvalue % 10;
      const SecondCheck = covertvalue % 100;
      if (FirstCheck == 1 && SecondCheck != 11) {
        return covertvalue + 'st';
      }
      if (FirstCheck == 2 && SecondCheck != 12) {
        return covertvalue + 'nd';
      }
      if (FirstCheck == 3 && SecondCheck != 13) {
        return covertvalue + 'rd';
      }
      return covertvalue + 'th';
    } else {
      return value;
    }
  };
  const StorePreviousDrpDwnValue = () => {
    setsuccessDropdownValues({
      ...successDropdownValues,
      Schedule: DropdownValues.Schedule,
      Term: TermValue,
      Deliverycharge: DropdownValues.DeliveryCharge,
      Duedate: Dropdown.Duedate,
      DaysExtension: DropdownValues.DayExtension,
      PromoCode: props.PromoCodeValue,
      OptionalService: OptServiceDropdownValue,
      Range: DropdownValues.Range,
      Policy: DropdownValues.Policy,
    });
  };
  const AgreementSourceOnchange = (value: any) => {
    const Recalculatereqobj: any = RecalculateReq;
    Recalculatereqobj.AgreementSourceId = value;
    setDropdownValues({ ...DropdownValues, AgreementSource: value });
    setRecalculateReq(Recalculatereqobj);
    props.GeneralInfoInputsChanged(Recalculatereqobj);
  };
  const DeliveryChargeOnchange = (value: any) => {
    StorePreviousDrpDwnValue();
    const Recalculatereqobj: any = RecalculateReq;
    Recalculatereqobj.DeliveryCharge = value;
    setDropdownChanged({ ...DropdownChanged, DeliveryCharge: true });
    setRecalculateReq(Recalculatereqobj);
    setDropdownValues({ ...DropdownValues, DeliveryCharge: value });
  };
  const DueDateOnchange = (value: any) => {
    StorePreviousDrpDwnValue();
    console.log('duedateonchange', value);
    if (value !== '') {
      const Recalculatereqobj: any = RecalculateReq;
      setDropdownChanged({ ...DropdownChanged, Duedate: true });
      const DuedateArr = value.split('-');
      let Dueformat = '';
      Dueformat = DuedateArr[1] + '/' + DuedateArr[2] + '/' + DuedateArr[0];
      Recalculatereqobj.Duedate = Dueformat.toString();
      setDropdown({ ...Dropdown, Duedate: value });
      Recalculatereqobj.ExtensionDays = 0;
      Recalculatereqobj.isLDWWaived = false;
      setDropdownValues({ ...DropdownValues, DayExtension: '0' });
      props.setWaiveLDWChecked(false);
      setRecalculateReq(Recalculatereqobj);
    }
  };
  const DaysExtensionOnchange = (value: any) => {
    StorePreviousDrpDwnValue();
    const Recalculatereqobj: any = RecalculateReq;
    setDropdownChanged({ ...DropdownChanged, ExtensionDays: true });
    Recalculatereqobj.ExtensionDays = parseFloat(value);
    value == '0'
      ? ((Recalculatereqobj.isLDWWaived = false),
        props.setWaiveLDWChecked(false))
      : null;
    setRecalculateReq(Recalculatereqobj);
    props.setWaiveLDWChecked(false);
    setDropdownValues({ ...DropdownValues, DayExtension: value.toString() });
  };
  const OptServicesOnchange = (value: any) => {
    StorePreviousDrpDwnValue();
    const Trimvalue = value.trim();
    console.log(
      'ClubStatus',
      props.ClubStatus,
      'IsLdwTiedWithClub',
      props.IsLdwTiedWithClub,
      'LDWstatus',
      props.LDWstatus,
      'IsLdwTiedWithClub',
      props.IsLdwTiedWithClub
    );
    if (
      !CLUB_STATUS_ACTIVE_PENDING.includes(props.ClubStatus) &&
      props.IsLdwTiedWithClub === true &&
      props.LDWstatus !== 'A' &&
      Trimvalue === 'LDWC'
    ) {
      console.log('setAddingBpclubOpen');
      setAddingBpclubOpen(true);
    } else if (
      CLUB_STATUS_ACTIVE_PENDING.includes(props.ClubStatus) &&
      props.IsLdwTiedWithClub === true &&
      Trimvalue === 'NONE' &&
      props.ClubOriginStore == window.sessionStorage.getItem('storeNumber')
    ) {
      console.log('setDecliningLdwOpen');
      setDecliningLdwOpen(true);
    } else {
      console.log('Normal optservice change');
      const Recalculatereqobj: any = RecalculateReq;
      setDropdownChanged({ ...DropdownChanged, Optservices: true });
      if (Trimvalue === 'LDWC') {
        Recalculatereqobj.optionalservice = {
          isLDWapplied: true,
          isClubApplied: true,
          isTirepolicyApplied: false,
        };
      } else if (Trimvalue === 'NONE') {
        Recalculatereqobj.optionalservice = {
          isLDWapplied: false,
          isClubApplied: false,
          isTirepolicyApplied: false,
        };
        Recalculatereqobj.isLDWWaived = false;
        props.setWaiveLDWChecked(false);
      } else if (Trimvalue === 'LDW') {
        Recalculatereqobj.optionalservice = {
          isLDWapplied: true,
          isClubApplied: false,
          isTirepolicyApplied: false,
        };
      }
      setRecalculateReq(Recalculatereqobj);
      setOptServiceDropdownValue(value);
    }
  };
  const SalesPersonOnchange = (value: any) => {
    const Recalculatereqobj: any = RecalculateReq;
    Recalculatereqobj.SalespersonId = parseInt(value);
    setDropdownValues({ ...DropdownValues, SalesPerson: value });
    setRecalculateReq(Recalculatereqobj);
    props.GeneralInfoInputsChanged(Recalculatereqobj);
  };
  const PromoCodeOnchange = (value: any) => {
    StorePreviousDrpDwnValue();
    const Recalculatereqobj: any = RecalculateReq;

    setDropdownChanged({ ...DropdownChanged, PromoCode: true });
    if (value === '') {
      props.setPromoCodeValue(value);
      Recalculatereqobj.PromoCode = null;
    } else {
      Recalculatereqobj.ExtensionDays = 0;
      Recalculatereqobj.isLDWWaived = false;
      Recalculatereqobj.PromoCode = value;
      props.setPromoCodeValue(value);
      setDropdownValues({
        ...DropdownValues,
        DayExtension: '0',
      });
    }
    props.setWaiveLDWChecked(false);
    setRecalculateReq(Recalculatereqobj);
  };
  const PolicyOnchange = (value: any) => {
    StorePreviousDrpDwnValue();
    const Recalculatereqobj: any = RecalculateReq;
    setDropdownValues({ ...DropdownValues, Policy: value });
    setDropdownChanged({ ...DropdownChanged, Policy: true });
    if (value === 'None') {
      Recalculatereqobj.PolicyApplied = false;
    } else {
      Recalculatereqobj.PolicyApplied = true;
    }
    setRecalculateReq(Recalculatereqobj);
  };
  const RangeOnchange = (value: any) => {
    StorePreviousDrpDwnValue();
    const Recalculatereqobj: any = RecalculateReq;
    Recalculatereqobj.Schedule = value;
    Recalculatereqobj.Duedate = null;
    Recalculatereqobj.ExtensionDays = 0;
    Recalculatereqobj.isLDWWaived = false;
    setRecalculateReq(Recalculatereqobj);
    setDropdownValues({ ...DropdownValues, Range: value, DayExtension: '0' });
    props.setWaiveLDWChecked(false);
    setDropdownChanged({ ...DropdownChanged, Range: true });
  };

  function ScheduleChange(value: any) {
    StorePreviousDrpDwnValue();
    const recalculateobj: any = RecalculateReq;
    const scheduletype = value;
    setDropdownChanged({ ...DropdownChanged, Schedule: true });
    setSelectedSchedule(scheduletype);

    if (scheduletype === 'WK') {
      setSchedule({
        ...Schedule,
        WeeklySchedule: true,
        BiWeeklySchedule: false,
        MonthlySchedule: false,
        SemiMonthlySchedule: false,
      });
      setRangeactive(false);
      setTermValue(PayScheduleVal.weeklyTerm);
      recalculateobj.Schedule = 'WK';
      recalculateobj.Duedate = null;
      recalculateobj.ExtensionDays = 0;
      recalculateobj.isLDWWaived = false;
      setDropdownValues({
        ...DropdownValues,
        Schedule: 'WK',
        DayExtension: '0',
      });
      props.setWaiveLDWChecked(false);
    } else if (scheduletype === 'BIWK') {
      setSchedule({
        ...Schedule,
        WeeklySchedule: false,
        BiWeeklySchedule: true,
        MonthlySchedule: false,
        SemiMonthlySchedule: false,
      });
      setRangeactive(false);
      setTermValue(PayScheduleVal.biweeklyTerm);
      recalculateobj.Schedule = 'BIWK';
      recalculateobj.Duedate = null;
      recalculateobj.ExtensionDays = 0;
      recalculateobj.isLDWWaived = false;
      setDropdownValues({
        ...DropdownValues,
        Schedule: 'BIWK',
        DayExtension: '0',
      });
      props.setWaiveLDWChecked(false);
    } else if (scheduletype === 'MON') {
      setSchedule({
        ...Schedule,
        WeeklySchedule: false,
        BiWeeklySchedule: false,
        MonthlySchedule: true,
        SemiMonthlySchedule: false,
      });
      setRangeactive(false);
      setTermValue(PayScheduleVal.monthlyTerm);
      recalculateobj.Schedule = 'MON';
      recalculateobj.Duedate = null;
      recalculateobj.ExtensionDays = 0;
      recalculateobj.isLDWWaived = false;
      setDropdownValues({
        ...DropdownValues,
        Schedule: 'MON',
        DayExtension: '0',
      });
      props.setWaiveLDWChecked(false);
    } else if (scheduletype === 'SEMI1-15') {
      setSchedule({
        ...Schedule,
        WeeklySchedule: false,
        BiWeeklySchedule: false,
        MonthlySchedule: false,
        SemiMonthlySchedule: true,
      });
      setRangeactive(true);
      setTermValue(PayScheduleVal.semimonthlyTerm);
      recalculateobj.Schedule = DropdownValues.Range;
      recalculateobj.Duedate = null;
      recalculateobj.ExtensionDays = 0;
      recalculateobj.isLDWWaived = false;
      setDropdownValues({
        ...DropdownValues,
        Schedule: 'SEMI1-15',
        DayExtension: '0',
      });
      props.setWaiveLDWChecked(false);
    } else {
      setRangeactive(false);
    }
  }

  function EnrollAutoPay() {
    if (AutoPayToggle === true) {
      props.setAutoPayToggleStatus(false);
    } else if (AutoPayToggle === false) {
      props.setAutoPayToggleStatus(true);
      props.PopupOpen.AutoPayOpen = true;
    }
  }
  const waiveLDWchange = () => {
    const Recalculatereqobj: any = RecalculateReq;
    if (!props.waiveLDWChecked) {
      props.setWaiveLDWChecked(true);
      Recalculatereqobj.isLDWWaived = true;
      props.RecalculateClicked(Recalculatereqobj);
    } else {
      props.setWaiveLDWChecked(false);
      Recalculatereqobj.isLDWWaived = false;
      props.RecalculateClicked(Recalculatereqobj);
    }
  };

  const DecliningLdwCloseClick = () => {
    setDecliningLdwOpen(false);
  };
  const AddingBpclubCloseClick = () => {
    setAddingBpclubOpen(false);
  };
  //PS_AD-3 getting price quote for changed values in dropdown
  const RecalculateBtnClick = async () => {
    try {
      console.log('Re', RecalculateReq);
      props.RecalculateClicked(RecalculateReq);
    } catch {
      throw Error;
    }
  };
  const AddingBenefitsPlusClubOkClick = () => {
    const Recalculatereqobj: any = RecalculateReq;
    setAddingBpclubOpen(false);
    setDropdownChanged({ ...DropdownChanged, Optservices: true });
    setOptServiceDropdownValue('LDWC');
    Recalculatereqobj.optionalservice = {
      isLDWapplied: true,
      isClubApplied: true,
      isTirepolicyApplied: false,
    };
    setRecalculateReq(Recalculatereqobj);
  };
  const AddingBenefitsPlusClubNoClick = () => {
    setAddingBpclubOpen(false);
  };
  const DecliningLdwOkclick = () => {
    const Recalculatereqobj: any = RecalculateReq;
    setDecliningLdwOpen(false);
    setOptServiceDropdownValue('NONE');
    setDropdownChanged({ ...DropdownChanged, Optservices: true });
    Recalculatereqobj.optionalservice = {
      isLDWapplied: false,
      isClubApplied: false,
      isTirepolicyApplied: false,
    };
    Recalculatereqobj.isLDWWaived = false;
    props.setWaiveLDWChecked(false);
    setRecalculateReq(Recalculatereqobj);
  };
  const DecliningLdwNoclick = () => {
    setDecliningLdwOpen(false);
  };
  const PaymentScheduleChange = (value: any) => {
    if (props.EditDisablemode === false && DropdownValues.Schedule !== value) {
      StorePreviousDrpDwnValue();
      const recalculateobj: any = RecalculateReq;
      const scheduletype = value;
      setDropdownChanged({ ...DropdownChanged, Schedule: true });
      setSelectedSchedule(scheduletype);

      if (scheduletype === 'WK') {
        setSchedule({
          ...Schedule,
          WeeklySchedule: true,
          BiWeeklySchedule: false,
          MonthlySchedule: false,
          SemiMonthlySchedule: false,
        });
        setRangeactive(false);
        setTermValue(PayScheduleVal.weeklyTerm);
        recalculateobj.Schedule = 'WK';
        recalculateobj.Duedate = null;
        recalculateobj.ExtensionDays = 0;
        recalculateobj.isLDWWaived = false;
        setDropdownValues({
          ...DropdownValues,
          Schedule: 'WK',
          DayExtension: '0',
        });
        props.setWaiveLDWChecked(false);
      } else if (scheduletype === 'BIWK') {
        setSchedule({
          ...Schedule,
          WeeklySchedule: false,
          BiWeeklySchedule: true,
          MonthlySchedule: false,
          SemiMonthlySchedule: false,
        });
        setRangeactive(false);
        setTermValue(PayScheduleVal.biweeklyTerm);
        recalculateobj.Schedule = 'BIWK';
        recalculateobj.Duedate = null;
        recalculateobj.ExtensionDays = 0;
        recalculateobj.isLDWWaived = false;
        setDropdownValues({
          ...DropdownValues,
          Schedule: 'BIWK',
          DayExtension: '0',
        });
        props.setWaiveLDWChecked(false);
      } else if (scheduletype === 'MON') {
        setSchedule({
          ...Schedule,
          WeeklySchedule: false,
          BiWeeklySchedule: false,
          MonthlySchedule: true,
          SemiMonthlySchedule: false,
        });
        setRangeactive(false);
        setTermValue(PayScheduleVal.monthlyTerm);
        recalculateobj.Schedule = 'MON';
        recalculateobj.Duedate = null;
        recalculateobj.ExtensionDays = 0;
        recalculateobj.isLDWWaived = false;
        setDropdownValues({
          ...DropdownValues,
          Schedule: 'MON',
          DayExtension: '0',
        });
        props.setWaiveLDWChecked(false);
      } else if (scheduletype === 'SEMI1-15') {
        setSchedule({
          ...Schedule,
          WeeklySchedule: false,
          BiWeeklySchedule: false,
          MonthlySchedule: false,
          SemiMonthlySchedule: true,
        });
        setRangeactive(true);
        setTermValue(PayScheduleVal.semimonthlyTerm);
        recalculateobj.Schedule = DropdownValues.Range;
        recalculateobj.Duedate = null;
        recalculateobj.ExtensionDays = 0;
        recalculateobj.isLDWWaived = false;
        setDropdownValues({
          ...DropdownValues,
          Schedule: 'SEMI1-15',
          DayExtension: '0',
        });
        props.setWaiveLDWChecked(false);
      } else {
        setRangeactive(false);
      }
    }
  };
  const AddingBenefitsPlusClubPopup = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            By choosing the option ‘LDW/Club’, you will be adding Benefit Plus
            club. LDW fee will be added on your current agreement and any
            existing agreements which do not have LDW.
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              Do you want to Continue?
            </Typography>
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => AddingBenefitsPlusClubNoClick()}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => AddingBenefitsPlusClubOkClick()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const DecliningLdwPopup = () => {
    return (
      <Grid className="">
        <Grid className={classes.textCenter}>
          <Typography>
            Declining LDW on this agreement will also terminate your Benefits
            Plus Coverage. You would not be able to reactivate club to cover any
            previous Paid In Full merchandise.
            <Typography className={`${classes.mt2} ${classes.fontBold}`}>
              Do you still wish to terminate?
            </Typography>
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            onClick={() => DecliningLdwNoclick()}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => DecliningLdwOkclick()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <div data-testid="GeneralInformationTest">
      {/* Adding LDW in previous agreements popup starts here */}
      <RACModalCard
        isOpen={AddingBpclubOpen}
        closeIcon={true}
        onClose={() => AddingBpclubCloseClick()}
        maxWidth="xs"
        borderRadius={'25px !important'}
      >
        {AddingBenefitsPlusClubPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={DecliningLdwOpen}
        onClose={() => DecliningLdwCloseClick()}
        closeIcon={true}
        maxWidth="xs"
        borderRadius={'25px !important'}
      >
        {DecliningLdwPopup()}
      </RACModalCard>
      <Grid container className={classes.mt2}>
        <Typography variant="h6" className={`${classes.title} ${classes.mb1}`}>
          Payment Schedule
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6} md={3} className={classes.mb2}>
          <Card
            className={
              Schedule.WeeklySchedule === true
                ? `${classes.card} ${classes.payScheduleHighlight}`
                : `${classes.card}`
            }
          >
            <CardContent>
              <Grid
                container
                alignItems="center"
                onClick={() => PaymentScheduleChange('WK')}
              >
                <Grid item md={6}>
                  <Typography
                    className={
                      Schedule.WeeklySchedule === true
                        ? classes.paySchedulePeriodHighlight
                        : classes.paySchedulePeriodNotHighlight
                    }
                    variant="body1"
                  >
                    Weekly
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  {PaymentScheduleValues.weeklyRate !== 0 ||
                    PaymentScheduleValues.weeklyTerm !== 0 ? (
                    <>
                      <Typography
                        className={
                          Schedule.WeeklySchedule === true
                            ? `${classes.payScheduleRateHighlight} ${classes.payAmount}`
                            : `${classes.payAmount}`
                        }
                      >
                        $ {PaymentScheduleValues.weeklyRate}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={
                          Schedule.WeeklySchedule === true
                            ? `${classes.payScheduleTermHighlight} ${classes.termAlign}`
                            : `${classes.termAlign}`
                        }
                      >
                        Term
                        <Typography
                          className={
                            Schedule.WeeklySchedule === true
                              ? `${classes.payScheduleValueHighlight} ${classes.paymentTerm}`
                              : `${classes.paymentTerm}`
                          }
                          variant="caption"
                        >
                          {PaymentScheduleValues.weeklyTerm}
                        </Typography>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography className={`${classes.payAmount}`}>
                        <Skeleton
                          count={1}
                          baseColor="#FFFFFF"
                          highlightColor="#e7e7e7"
                        />
                      </Typography>
                      <Typography
                        variant="body1"
                        className={`${classes.termAlign}`}
                      >
                        <Skeleton
                          count={1}
                          baseColor="#FFFFFF"
                          highlightColor="#e7e7e7"
                        />
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item sm={6} md={3} className={classes.mb2}>
          <Card
            className={
              Schedule.BiWeeklySchedule === true
                ? `${classes.card} ${classes.payScheduleHighlight}`
                : `${classes.card}`
            }
            onClick={() => PaymentScheduleChange('BIWK')}
          >
            <CardContent>
              <Grid container alignItems="center">
                <Grid item md={6}>
                  <Typography
                    className={
                      Schedule.BiWeeklySchedule === true
                        ? classes.paySchedulePeriodHighlight
                        : classes.paySchedulePeriodNotHighlight
                    }
                    variant="body1"
                  >
                    Bi-Weekly
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  {PaymentScheduleValues.biweeklyRate !== 0 ||
                    PaymentScheduleValues.biweeklyTerm !== 0 ? (
                    <>
                      <Typography
                        className={
                          Schedule.BiWeeklySchedule === true
                            ? `${classes.payScheduleRateHighlight} ${classes.payAmount}`
                            : `${classes.payAmount}`
                        }
                      >
                        $ {PaymentScheduleValues.biweeklyRate}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={
                          Schedule.BiWeeklySchedule === true
                            ? `${classes.payScheduleTermHighlight} ${classes.termAlign}`
                            : `${classes.termAlign}`
                        }
                      >
                        Term
                        <Typography
                          variant="caption"
                          className={
                            Schedule.BiWeeklySchedule === true
                              ? `${classes.payScheduleValueHighlight} ${classes.paymentTerm}`
                              : `${classes.paymentTerm}`
                          }
                        >
                          {PaymentScheduleValues.biweeklyTerm}
                        </Typography>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography className={`${classes.payAmount}`}>
                        <Skeleton
                          count={1}
                          baseColor="#FFFFFF"
                          highlightColor="#e7e7e7"
                        />
                      </Typography>
                      <Typography
                        variant="body1"
                        className={`${classes.termAlign}`}
                      >
                        <Skeleton
                          count={1}
                          baseColor="#FFFFFF"
                          highlightColor="#e7e7e7"
                        />
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item sm={6} md={3} className={classes.mb2}>
          <Card
            className={
              Schedule.SemiMonthlySchedule === true
                ? `${classes.card} ${classes.payScheduleHighlight}`
                : `${classes.card}`
            }
            onClick={() => PaymentScheduleChange('SEMI1-15')}
          >
            <CardContent>
              <Grid container alignItems="center">
                <Grid item md={6}>
                  <Typography
                    className={
                      Schedule.SemiMonthlySchedule === true
                        ? classes.paySchedulePeriodHighlight
                        : classes.paySchedulePeriodNotHighlight
                    }
                    variant="body1"
                  >
                    Semi-Monthly
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  {PaymentScheduleValues.semimonthlyRate !== 0 ||
                    PaymentScheduleValues.semimonthlyTerm !== 0 ? (
                    <>
                      <Typography
                        className={
                          Schedule.SemiMonthlySchedule === true
                            ? `${classes.payScheduleRateHighlight} ${classes.payAmount}`
                            : `${classes.payAmount}`
                        }
                      >
                        $ {PaymentScheduleValues.semimonthlyRate}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={
                          Schedule.SemiMonthlySchedule === true
                            ? `${classes.payScheduleTermHighlight} ${classes.termAlign}`
                            : `${classes.termAlign}`
                        }
                      >
                        Term
                        <Typography
                          variant="caption"
                          className={
                            Schedule.SemiMonthlySchedule === true
                              ? `${classes.payScheduleValueHighlight} ${classes.paymentTerm}`
                              : `${classes.paymentTerm}`
                          }
                        >
                          {PaymentScheduleValues.semimonthlyTerm}
                        </Typography>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography className={`${classes.payAmount}`}>
                        <Skeleton
                          count={1}
                          baseColor="#FFFFFF"
                          highlightColor="#e7e7e7"
                        />
                      </Typography>
                      <Typography
                        variant="body1"
                        className={`${classes.termAlign}`}
                      >
                        <Skeleton
                          count={1}
                          baseColor="#FFFFFF"
                          highlightColor="#e7e7e7"
                        />
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item sm={6} md={3} className={classes.mb2}>
          <Card
            className={
              Schedule.MonthlySchedule === true
                ? `${classes.card} ${classes.payScheduleHighlight}`
                : `${classes.card}`
            }
            onClick={() => PaymentScheduleChange('MON')}
          >
            <CardContent>
              <Grid container alignItems="center">
                <Grid item md={6}>
                  <Typography
                    className={
                      Schedule.MonthlySchedule === true
                        ? classes.paySchedulePeriodHighlight
                        : classes.paySchedulePeriodNotHighlight
                    }
                    variant="body1"
                  >
                    Monthly
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  {PaymentScheduleValues.monthlyRate !== 0 ||
                    PaymentScheduleValues.monthlyTerm !== 0 ? (
                    <>
                      {' '}
                      <Typography
                        className={
                          Schedule.MonthlySchedule === true
                            ? `${classes.payScheduleRateHighlight} ${classes.payAmount}`
                            : `${classes.payAmount}`
                        }
                      >
                        $ {PaymentScheduleValues.monthlyRate}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={
                          Schedule.MonthlySchedule === true
                            ? `${classes.payScheduleTermHighlight} ${classes.termAlign}`
                            : `${classes.termAlign}`
                        }
                      >
                        Term
                        <Typography
                          variant="caption"
                          className={paymentScheduleClassName.paymentterm}
                        >
                          {PaymentScheduleValues.monthlyTerm}
                        </Typography>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography className={`${classes.payAmount}`}>
                        <Skeleton
                          count={1}
                          baseColor="#FFFFFF"
                          highlightColor="#e7e7e7"
                        />
                      </Typography>
                      <Typography
                        variant="body1"
                        className={`${classes.termAlign}`}
                      >
                        <Skeleton
                          count={1}
                          baseColor="#FFFFFF"
                          highlightColor="#e7e7e7"
                        />
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container className={classes.mt1}>
        <Typography variant="h6" className={`${classes.title} ${classes.mb1}`}>
          General Information
        </Typography>
      </Grid>

      <Grid container>
        <Grid item md={12} className={classes.mb4}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container className={classes.mb2}>
                <Grid item className={agrGeneralInfoClassName.listitemstyles}>
                  <Grid
                    item
                    xs="auto"
                    className={`${agrGeneralInfoClassName.paymentGeneralInfo} ${agrGeneralInfoClassName.borderRightRadius0} ${classes.pt1}`}
                  >
                    <Typography
                      variant="caption"
                      className={
                        agrGeneralInfoClassName.paymentGeneralInfoColor
                      }
                    >
                      Agreement Type :{' '}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={
                        agrGeneralInfoClassName.paymentGeneralInfoColorlabel
                      }
                    >
                      {' ' + AgreementType}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs="auto"
                    className={`${agrGeneralInfoClassName.paymentGeneralInfo} ${agrGeneralInfoClassName.borderRightRadius0} ${classes.pt1}`}
                  >
                    <Typography
                      variant="caption"
                      className={
                        agrGeneralInfoClassName.paymentGeneralInfoColor
                      }
                    >
                      {'  '}Pay Schedule :{' '}
                    </Typography>

                    {PaymentScheduleEnabled === true ? (
                      <Typography
                        variant="caption"
                        className={
                          agrGeneralInfoClassName.paymentGeneralInfoColorlabel
                        }
                      >
                        {'  '}
                        {Payschedule.PayschedulePeriod !== 'Daily' &&
                          Payschedule.PayschedulePeriod !== 'daily'
                          ? Payschedule.PayschedulePeriod +
                          ' on ' +
                          Payschedule.PayscheduleDay
                          : Payschedule.PayschedulePeriod}
                      </Typography>
                    ) : (
                      <Typography
                        variant="caption"
                        className={
                          agrGeneralInfoClassName.paymentGeneralInfoColor
                        }
                      >
                        {'  '}-
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={4} md={2}>
                  <RACSelect
                    inputLabelClassName={agrGeneralInfoClassName.dropDownLabel}
                    options={DropdownOptions.AgreementSource}
                    defaultValue={DropdownValues.AgreementSource}
                    isDisabled={DropDownDisable}
                    inputLabel="Agreement Source"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      AgreementSourceOnchange(e.target.value);
                    }}
                    loading={DropdownLoading}
                    {...(DropdownOptions.AgreementSource.length == 0 &&
                      dropDownLoaded && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>

                <Grid item sm={4} md={2}>
                  <RACSelect
                    inputLabelClassName={agrGeneralInfoClassName.dropDownLabel}
                    options={DropdownOptions.Schedulevalues}
                    name="Schedule"
                    defaultValue={DropdownValues.Schedule}
                    inputLabel="Schedule"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      ScheduleChange(e.target.value);
                    }}
                    isDisabled={DropDownDisable}
                    loading={DropdownLoading}
                    {...(DropdownOptions.Schedulevalues.length == 0 &&
                      dropDownLoaded && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                  <Typography variant="body1" className={classes.font14}>
                    Term
                    <Typography
                      variant="caption"
                      className={`${classes.bold} ${classes.racTextGreen} ${classes.ms1}`}
                    >
                      {TermValue}
                    </Typography>
                  </Typography>
                </Grid>

                {Rangeactive === true ? (
                  <Grid item sm={4} md={2}>
                    <RACSelect
                      inputLabelClassName={
                        agrGeneralInfoClassName.dropDownLabel
                      }
                      options={DropdownOptions.Range}
                      name="Range"
                      defaultValue={DropdownValues.Range}
                      inputLabel="Range"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        RangeOnchange(e.target.value);
                      }}
                      isDisabled={DropDownDisable}
                      loading={DropdownLoading}
                      {...(DropdownOptions.Range.length == 0 &&
                        dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                    />
                  </Grid>
                ) : null}

                <Grid item sm={4} md={2}>
                  <RACSelect
                    inputLabelClassName={agrGeneralInfoClassName.dropDownLabel}
                    options={DeliveryChargeOptions}
                    defaultValue={DropdownValues.DeliveryCharge}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      DeliveryChargeOnchange(e.target.value);
                    }}
                    inputLabel="Delivery Charge ($)"
                    isDisabled={props.DeliveryChargeDisable}
                    loading={DropdownLoading}
                  />
                </Grid>
                <Grid item sm={4} md={2}>
                  <RACDatePicker
                    value={Dropdown.Duedate}
                    label="Due Date"
                    name="duedate"
                    disabled={DropDownDisable}
                    inputProps={{
                      min: props?.exchangeProps?.isExchangeAgreeement ? props?.exchangeAgrDueDate : CurrentDate,
                      max: MaxDate,
                    }}
                    classes={classes.datePickerMargin}
                    onChange={(value) => DueDateOnchange(value)}
                  />
                </Grid>
                <Grid item sm={4} md={2}>
                  <RACSelect
                    inputLabelClassName={agrGeneralInfoClassName.dropDownLabel}
                    options={DaysExtensionOptions}
                    defaultValue={DropdownValues.DayExtension}
                    isDisabled={
                      (props.PromoCodeValue !== '' ? true : props.EditDisablemode) || (props?.exchangeProps?.isExchangeAgreeement)
                    }
                    inputLabel="Days Extension"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      DaysExtensionOnchange(e.target.value);
                    }}
                    loading={DropdownLoading}
                  />
                </Grid>

                <Grid item sm={4} md={2}>
                  <RACSelect
                    inputLabelClassName={agrGeneralInfoClassName.dropDownLabel}
                    options={props.PromoCodeDropDownOptions}
                    name=" PromoCode"
                    defaultValue={props.PromoCodeValue}
                    isDisabled={
                      (DropDownDisable || props.PromoCodeDisable ? true : false) || (props?.exchangeProps?.isExchangeAgreeement)
                    }
                    inputLabel="Promo Code"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      PromoCodeOnchange(e.target.value);
                      setSalesInfoResponse ({
                        promoCode : e.target.value
                      })
                    }}
                    loading={DropdownLoading}
                  />
                </Grid>
                <Grid item sm={4} md={2}>
                  <RACSelect
                    inputLabelClassName={agrGeneralInfoClassName.dropDownLabel}
                    options={DropdownOptions.OPtServices}
                    name="Opt Services"
                    defaultValue={OptServiceDropdownValue}
                    isDisabled={OptServicesDisable}
                    inputLabel="Opt Services"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      OptServicesOnchange(e.target.value);
                    }}
                    loading={DropdownLoading}
                    {...(DropdownOptions.OPtServices.length == 0 &&
                      dropDownLoaded && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>
                {PolicyDropdownVisible === true ? (
                  <Grid item sm={4} md={2}>
                    <RACSelect
                      inputLabelClassName={
                        agrGeneralInfoClassName.dropDownLabel
                      }
                      options={PolicyDropDownValues}
                      name="Policy"
                      defaultValue={DropdownValues.Policy}
                      isDisabled={DropDownDisable}
                      inputLabel="Policy"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        PolicyOnchange(e.target.value);
                      }}
                      loading={DropdownLoading}
                    />
                  </Grid>
                ) : null}
                <Grid item sm={4} md={2}>
                  <RACSelect
                    inputLabelClassName={agrGeneralInfoClassName.dropDownLabel}
                    options={DropdownOptions.SalesPerson}
                    name=" Sales Person"
                    defaultValue={DropdownValues.SalesPerson}
                    isDisabled={DropDownDisable}
                    inputLabel="SalesPerson"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      SalesPersonOnchange(e.target.value);
                    }}
                    loading={DropdownLoading}
                    required={true}
                    {...(DropdownOptions.SalesPerson.length == 0 &&
                      dropDownLoaded && {
                      errorMessage: API_ERROR_MESSAGE,
                    })}
                  />
                </Grid>

                <Grid item className={classes.widthAuto}>
                  <Typography
                    variant="subtitle1"
                    className={agrGeneralInfoClassName.cardLabel}
                  >
                    Enroll Autopay
                  </Typography>
                  <Grid>
                    <Grid>
                      <RACToggle
                        disabled={DropDownDisable || props?.exchangeProps?.isExchangeAgreeement}
                        checked={AutoPayToggle}
                        toggleColor="#0d6efd"
                        OnChange={() => EnrollAutoPay()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {featureFlagDetails?.waiveLdw == '1' ? (
                  <Grid item className={classes.widthAuto}>
                    <Typography
                      variant="subtitle1"
                      className={
                        !OptServiceDropdownValue.includes('LDW')
                          ? agrGeneralInfoClassName.disabledColour
                          : agrGeneralInfoClassName.cardLabel
                      }
                    >
                      Waive LDW
                    </Typography>
                    <Grid>
                      <Grid>
                        <RACToggle
                          disabled={
                            DropdownValues.DayExtension == '0' ||
                              props.EditDisablemode ||
                              !OptServiceDropdownValue?.includes('LDW')
                              ? true
                              : false
                          }
                          checked={props.waiveLDWChecked}
                          toggleColor="#0d6efd"
                          OnChange={() => waiveLDWchange()}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
});
export default GeneralInformation;
